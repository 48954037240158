import '@scss/main.scss'

import './polyfills'
import 'picturefill'
import 'lazysizes'
import Swiper from 'swiper'
import Accordino from './libs/Accordino'
import * as basicScroll from 'basicscroll'
import Splitting from 'splitting'

document.addEventListener('DOMContentLoaded', function() {

    // Burger menu
    document.querySelector('.a-burger').addEventListener('click', e => {
        e.preventDefault()
        if(!document.querySelector('.o-body').classList.contains('o-body--menu-on')) {
            document.querySelector('.o-body').classList.add('o-body--menu-on')
        } else {
            document.querySelector('.o-body').classList.remove('o-body--menu-on')
        }
    })
    document.querySelector('.m-menu__background').addEventListener('click', () => {
        document.querySelector('.o-body').classList.remove('o-body--menu-on')
    })

    // Splitting
    Splitting({
        target: '.o-block--top .a-heading',
        by: 'lines'
    })

    // Init home parallax
    const homeScene = document.querySelector('.m-home__scene')
    if(homeScene) {
        // Image scroll
        basicScroll.create({
            elem: homeScene,
            from: '0',
            to: 'bottom-top',
            direct: true,
            props: {
                '--translate': {
                    from: '0px',
                    to: '100px'
                }
            }
        }).start()
    }

    // Init timeline appearance
    const steps = document.querySelectorAll('.m-timeline__step')
    if(steps && window.innerWidth >= 1024) {
        steps.forEach(step => {
            // Step appear with opacity
            basicScroll.create({
                elem: step,
                from: 'middle-bottom',
                to: 'bottom-middle',
                direct: true,
                props: {
                    '--opacity': {
                        from: '0',
                        to: '0.99'
                    },
                    '--translate': {
                        from: '60px',
                        to: '0px',
                        timing: 'sineOut',
                    }
                }
            }).start()
        })
    }

    // Filiale slider
    const slider = document.querySelector('.m-slider')
    if(slider) {
        if(slider.querySelectorAll('.swiper-slide').length > 1) {
            new Swiper(slider, {
                speed: 600,
                autoplay: {
                    delay: 2400,
                },
                grabCursor: true,
                threshold: 10,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            })
        } else {
            slider.classList.add('m-slider--no-swiper')
        }
    }

    // Accordions on values
    const accordions = document.querySelectorAll('.accordino')
    if(accordions) {
        accordions.forEach(accordion => {
            // console.log(accordion)
            new Accordino(accordion)
        })
    }

    // Interactive map
    const logos = document.querySelectorAll('.m-view-liste_des_filiales_map__row')
    const pointers = document.querySelectorAll('.m-subsidiaries__pointer')
    if(logos && pointers && !isIE()) {
        // Pointer echo when logo hover
        const map = document.querySelector('.m-subsidiaries__map')
        logos.forEach(logo => {
            const location = logo.querySelector('a').dataset.location
            // Split locations
            const pointers = location.split(',')
            pointers.splice(-1,1)
            if(pointers.length) {
                logo.addEventListener('mouseover', () => {
                    pointers.forEach(pointer => {
                        map.querySelector(`[data-id="${pointer}"]`).classList.add('m-subsidiaries__pointer--active')
                    })
                })
                logo.addEventListener('mouseout', () => {
                    pointers.forEach(pointer => {
                        map.querySelector(`[data-id="${pointer}"]`).classList.remove('m-subsidiaries__pointer--active')
                    })
                })
            }
            // Add classes on locations
            pointers.forEach(pointer => {
                logo.classList.add(`m-view-liste_des_filiales_map__row--${pointer}`)
            })
        })
        // Logo hover effect when pointer hover
        const logosContainer = document.querySelector('.m-view-liste_des_filiales_map')
        pointers.forEach(pointer => {
            pointer.addEventListener('mouseover', () => {
                logosContainer.classList.add('m-view-liste_des_filiales_map--active')
                // Get all logos wich should be active and add active class
                const activeLogos = logosContainer.querySelectorAll(`.m-view-liste_des_filiales_map__row--${pointer.dataset.id}`)
                activeLogos.forEach(activeLogo => {
                    activeLogo.classList.add('m-view-liste_des_filiales_map__row--active')
                })
            })
            pointer.addEventListener('mouseout', () => {
                logosContainer.classList.remove('m-view-liste_des_filiales_map--active')
                // Get all logos wich should be active and add remove class
                const activeLogos = logosContainer.querySelectorAll(`.m-view-liste_des_filiales_map__row--${pointer.dataset.id}`)
                activeLogos.forEach(activeLogo => {
                    activeLogo.classList.remove('m-view-liste_des_filiales_map__row--active')
                })
            })
        })
    }

    // Generate aleatory decorations
    if(window.innerWidth >= 1280 && !isIE()) {
        const range = 1000
        const decorations = [
            { id: 'deco-1', viewbox: '0 0 140 241' }, 
            { id: 'deco-2', viewbox: '0 0 233.99 434.83' },
            { id: 'deco-3', viewbox: '0 0 69.2 200.9' },
            { id: 'deco-4', viewbox: '0 0 120 111' }
        ]
        const colors = [
            '#fab639',
            // '#091f58',
            '#99d5eb',
            // '#b8b1af'
        ]
        const topBlock = document.querySelector('.o-block--top')
        const footer = document.querySelector('.o-footer')
        const top = topBlock.offsetTop + topBlock.offsetHeight
        const bot = footer.offsetTop
        // Get initial position
        let pos = top + getRandomInt(range / -4, range / 2)
        // Get initial side
        // let side = !!getRandomInt(0, 1)
        let side = 0
        // Get new positions until we reach footer
        for (let i = 0; pos < bot; i++) {
            // Check pos
            if(pos > bot)
                return false
            // Get random decoration
            const decoration = decorations[getRandomInt(0, decorations.length - 1)]
            // Create SVG
            const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
            svg.setAttribute('viewBox', decoration.viewbox)
            const use = document.createElementNS('http://www.w3.org/2000/svg', 'use')
            use.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `#${decoration.id}`)
            svg.appendChild(use)
            svg.classList.add('o-body__decoration')
            // Set side
            svg.classList.add(side ? 'o-body__decoration--right' : 'o-body__decoration--left')
            // Get random color
            const color = colors[getRandomInt(0, colors.length - 1)]
            svg.setAttribute('fill', color)
            // Set position
            svg.style.top = `${pos}px`
            // Append to body
            document.body.appendChild(svg)
            // Basic scroll 
            const fromTo = getRandomInt(70, 100)
            // const fromTo = 100
            basicScroll.create({
                elem: svg,
                from: 'top-bottom',
                to: 'bottom-top',
                direct: true,
                props: {
                    '--translate': {
                        from: `-${fromTo}px`,
                        to: `${fromTo}px`
                    }
                }
            }).start()
            // Increment position
            pos = pos + range + getRandomInt(range / -2, range / 2)
            // Switch side
            side = !side
        }
    }

    // Hide contentinfo on click
    const contentinfo = document.querySelector('.contentinfo')
    if(contentinfo) {
        contentinfo.addEventListener('click', () => {
            contentinfo.classList.add('contentinfo--hidden')
        })
    }

})

function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

function isIE() {
    var ua = window.navigator.userAgent
    var msie = ua.indexOf("MSIE ")
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        return true
    }
    return false
}

// SWPL LOG
console.log('%c S W P L ', 'background: #1467ff; color: white; font-size: 17px; font-weight: bold; line-height: 36px; text-align: center', ' www.swpl.fr');
